// Inspired by https://janosh.dev/blog/react-hooks-masonry
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

const useEventListener = (type, handler) => {
  const savedHandler = useRef()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    const listener = (event) => savedHandler.current(event)
    window.addEventListener(type, listener)
    return () => {
      window.removeEventListener(type, listener)
    }
  }, [type])
}

const fillCols = (children, cols) => {
  React.Children.toArray(children).forEach((child, i) =>
    cols[i % cols.length].push(child)
  )
}

function Masonry({ children, gap, minWidth, ...rest }) {
  const ref = useRef()
  const [numCols, setNumCols] = useState(3)
  const cols = [...Array(numCols)].map(() => [])
  fillCols(children, cols)

  const resizeHandler = () => {
    setNumCols(Math.floor(ref.current.offsetWidth / minWidth) || 1)
  }

  useEffect(resizeHandler, [minWidth])
  useEventListener(`resize`, resizeHandler)

  return (
    <div
      ref={ref}
      style={{ display: 'grid', gridAutoFlow: 'column', gridGap: gap }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {[...Array(numCols)].map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={i} style={{ display: 'grid', gridGap: gap }}>
          {cols[i]}
        </div>
      ))}
    </div>
  )
}

Masonry.propTypes = {
  children: PropTypes.node.isRequired,
  gap: PropTypes.string,
  minWidth: PropTypes.number,
}

Masonry.defaultProps = {
  gap: '1em',
  minWidth: 500,
}

export default Masonry
