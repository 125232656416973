import * as React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { CheckIcon } from '@heroicons/react/outline'

import Meta from '../components/Meta'
import Layout from '../components/Layout'
import Masonry from '../components/Masonry'

function Page({ location }) {
  const { shareImage } = useStaticQuery(graphql`
    query {
      shareImage: file(relativePath: { eq: "services/fencing-feature-3.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  return (
    <>
      <Meta
        title="Fencing"
        description="We install all types of fencing at ProGro Landscapes. Whether you are looking for security, privacy or an aesthetic definition of boundary, we can help you."
        location={location}
        image={shareImage}
      />
      <Layout>
        <section className="bg-white overflow-hidden">
          <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
            <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray">
                Fencing
              </h3>
            </div>
            <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="mt-8 lg:mt-0">
                <div className="text-base max-w-prose mx-auto lg:max-w-none">
                  <p className="text-lg text-gray-light">
                    We install all types of fencing at ProGro Landscapes.
                    Whether you are looking for security, privacy or an
                    aesthetic definition of boundary, we can help you. We can
                    advise and create a fence that suits your purpose and that
                    will last. We believe in using quality materials and build
                    our fences for the long term.
                  </p>
                </div>
              </div>
            </div>
            <ul className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
              <li className="relative">
                <CheckIcon
                  className="absolute h-6 w-6 text-green"
                  aria-hidden="true"
                />
                <p className="ml-9 text-lg leading-6 font-medium text-gray">
                  Closeboard Fencing
                </p>
              </li>
              <li className="relative">
                <CheckIcon
                  className="absolute h-6 w-6 text-green"
                  aria-hidden="true"
                />
                <p className="ml-9 text-lg leading-6 font-medium text-gray">
                  Panel Fencing
                </p>
              </li>
              <li className="relative">
                <CheckIcon
                  className="absolute h-6 w-6 text-green"
                  aria-hidden="true"
                />
                <p className="ml-9 text-lg leading-6 font-medium text-gray">
                  Knee Rail Fencing
                </p>
              </li>
              <li className="relative">
                <CheckIcon
                  className="absolute h-6 w-6 text-green"
                  aria-hidden="true"
                />
                <p className="ml-9 text-lg leading-6 font-medium text-gray">
                  Cedar Wood Fencing
                </p>
              </li>
              <li className="relative">
                <CheckIcon
                  className="absolute h-6 w-6 text-green"
                  aria-hidden="true"
                />
                <p className="ml-9 text-lg leading-6 font-medium text-gray">
                  Chainlink Fencing
                </p>
              </li>
              <li className="relative">
                <CheckIcon
                  className="absolute h-6 w-6 text-green"
                  aria-hidden="true"
                />
                <p className="ml-9 text-lg leading-6 font-medium text-gray">
                  Commercial Fencing
                </p>
              </li>
            </ul>
          </div>
        </section>
        <Masonry>
          <StaticImage
            src="../images/services/fencing-feature-1.jpg"
            alt="Featured work"
            aspectRatio={4 / 3}
            transformOptions={{ fit: 'inside' }}
            placeholder="blurred"
          />
          <StaticImage
            src="../images/services/fencing-feature-2.jpg"
            alt="Featured work"
            aspectRatio={4 / 3}
            transformOptions={{ fit: 'inside' }}
            placeholder="blurred"
          />
          <StaticImage
            src="../images/services/fencing-feature-3.jpg"
            alt="Featured work"
            aspectRatio={16 / 9}
            transformOptions={{ fit: 'inside' }}
            placeholder="blurred"
          />
          <StaticImage
            src="../images/services/fencing-feature-4.jpg"
            alt="Featured work"
            aspectRatio={16 / 9}
            transformOptions={{ fit: 'inside' }}
            placeholder="blurred"
          />
          <StaticImage
            src="../images/services/fencing-feature-5.jpg"
            alt="Featured work"
            aspectRatio={16 / 9}
            transformOptions={{ fit: 'inside' }}
            placeholder="blurred"
          />
          <StaticImage
            src="../images/services/fencing-feature-6.jpg"
            alt="Featured work"
            aspectRatio={16 / 9}
            transformOptions={{ fit: 'inside' }}
            placeholder="blurred"
          />
          <StaticImage
            src="../images/services/fencing-feature-7.jpg"
            alt="Featured work"
            aspectRatio={4 / 3}
            transformOptions={{ fit: 'inside' }}
            placeholder="blurred"
          />
          <StaticImage
            src="../images/services/fencing-feature-8.jpg"
            alt="Featured work"
            aspectRatio={4 / 3}
            transformOptions={{ fit: 'inside' }}
            placeholder="blurred"
          />
        </Masonry>
      </Layout>
    </>
  )
}

Page.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

export default Page
